import React from "react";
import { ContentSection, SplitConfig } from "@ryerson/frontend.layout";
import { Grid, Column, Row } from "@ryerson/frontend.layout";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { useTheme } from "@ryerson/frontend.theme";
import { Media } from "@ryerson/frontend.layout";

export type SlideImageTextProps = {
	content: SlideImageTextContent[];
};

export type SlideImageTextContent = {
	imageUrl: string;
	title: string;
	description: string;
	jumpToLink: string;
	jumpToText: string | string[];
};

type LeftImageProps = {
	content: SlideImageTextContent;
	background?: "primary" | "secondary" | "tertiary";
};

type RightImageProps = {
	content: SlideImageTextContent;
	background?: "primary" | "secondary" | "tertiary" | "split";
	split?: SplitConfig;
};

const LeftImageColumn = styled.div`
	width: 100%;
	display: block;
	height: auto;
`;

const LeftTextColumn = styled.div`
	width: 100%;
	max-width: 360px;
	height: auto;
	margin: 0 auto;
	display: block;
`;

const RightImageColumn = styled.div`
	width: 100%;
	display: block;
	height: auto;
`;

const RightTextColumn = styled.div`
	width: 100%;
	height: auto;
	padding: 80px 95px;
	box-sizing: border-box;
	margin-bottom: 45px;
	display: block;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.darkGray};
		`;
	}}
`;

const mobileImage = css`
	width: 100%;
	margin: 0px 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
`;

const Divider = styled.hr`
	width: 100%;
	opacity: 0.1;
	margin-bottom: -57px;
	${(props: any) => {
		const { theme } = props;
		return css`
			color: ${theme.colors.primary.darkerGray};
		`;
	}}
`;

const SolutionsHero: React.FC<SlideImageTextProps> = ({ content }) => {
	const { theme } = useTheme();

	const MobileSection: React.FC<SlideImageTextContent> = ({
		imageUrl,
		title,
		description,
		jumpToLink,
		jumpToText,
	}) => {
		const imageRef: any = React.createRef();
		const [imageHeight, setImageHeight] = React.useState("0px");
		React.useEffect(() => {
			const windowResized = (e?: any) => {
				if (imageRef && imageRef.current && imageRef.current.clientWidth) {
					setImageHeight(imageRef.current.clientWidth * 0.6 + "px");
				}
			};
			if (window) {
				window.addEventListener("resize", windowResized);
				windowResized();
			}
		}, []);
		return (
			<>
				<div
					ref={imageRef}
					style={{ backgroundImage: "url(" + imageUrl + ")", height: imageHeight }}
					css={mobileImage}
				/>
				<Typography
					variant="h3"
					css={css`
						margin-top: 25px;
						margin-bottom: 17px;
					`}
					type="primary"
				>
					{title}
				</Typography>
				<Typography
					variant="div"
					css={css`
						margin-bottom: 30px;
					`}
					size="md"
					type="primary"
				>
					{description}
				</Typography>
				<div
					css={css`
						display: block;
						margin-bottom: 25px;
					`}
				>
					<ButtonLink
						to={jumpToLink ?? "#"}
						label={jumpToText}
						type="primary"
						orientation="left"
						linkType="internal"
					/>
				</div>
			</>
		);
	};

	const LeftImage: React.FC<LeftImageProps> = ({ background = "primary", content }) => {
		return (
			<ContentSection vPadding="136px" type={background}>
				<Grid>
					<Row>
						<Column xs={12} sm={12} md={12} lg={6} xl={6}>
							<LeftImageColumn>
								<img
									src={content.imageUrl}
									style={{ width: "100%", height: "auto" }}
								/>
							</LeftImageColumn>
						</Column>
						<Column xs={12} sm={12} md={12} lg={6} xl={6}>
							<LeftTextColumn>
								<Typography
									variant="h1"
									css={{ marginTop: 0, marginBottom: "60px" }}
									type={background}
								>
									{content.title}
								</Typography>
								<Typography
									type={background}
									variant="div"
									css={css`
										margin-bottom: 40px;
									`}
								>
									{content.description}
								</Typography>
								<ButtonLink
									to={content.jumpToLink ?? "#"}
									label={content.jumpToText}
									type="primary"
									orientation="left"
									linkType="internal"
								/>
							</LeftTextColumn>
						</Column>
					</Row>
				</Grid>
			</ContentSection>
		);
	};

	const RightImage: React.FC<RightImageProps> = ({ background = "primary", content, split }) => {
		return (
			<ContentSection type={background} vPadding="107px" split={split ? split : undefined}>
				<Grid>
					<Row>
						<Column gutter={0} xs={12} sm={12} md={12} lg={6} xl={6}>
							<RightTextColumn theme={theme}>
								<Typography
									variant="h1"
									css={{ marginTop: 0, marginBottom: "60px" }}
									color={theme.colors.primary.white}
								>
									{content.title}
								</Typography>
								<Typography color={theme.colors.primary.white}>
									{content.description}
								</Typography>
							</RightTextColumn>
							<ButtonLink
								to={content.jumpToLink ?? "#"}
								label={content.jumpToText}
								type="primary"
								orientation="left"
								linkType="internal"
							/>
						</Column>
						<Column gutter={0} xs={12} sm={12} md={12} lg={6} xl={6}>
							<RightImageColumn>
								<img
									src={content.imageUrl}
									style={{ width: "100%", height: "auto" }}
								/>
							</RightImageColumn>
						</Column>
					</Row>
				</Grid>
			</ContentSection>
		);
	};

	return (
		<>
			<Media greaterThanOrEqual="lg">
				{content.map((sectionContent: SlideImageTextContent, index: number) => {
					return (
						<>
							{index % 2 === 0 ? (
								<LeftImage
									background={index === 0 ? "secondary" : "primary"}
									content={sectionContent}
								/>
							) : (
								<RightImage
									background="split"
									content={sectionContent}
									split={{
										splitDirection: "vertical",
										splitColors: [
											{
												color: theme.colors.tertiary.background,
												percentage: "50%",
											},
											{
												color: theme.colors.primary.background,
												percentage: "50%",
											},
										],
									}}
								/>
							)}
						</>
					);
				})}
			</Media>
			<Media lessThan="lg">
				<ContentSection type="primary" vPadding="46px">
					{content.map((mobileContent: SlideImageTextContent, index: number) => {
						return <MobileSection {...mobileContent} />;
					})}
					<Divider theme={theme} />
				</ContentSection>
			</Media>
		</>
	);
};

export default SolutionsHero;
